const ePartnerConfigProperties = {
  EPCP_JACKPOT_ENABLED: 'jackpotEnabled',
  EPCP_IFRAME_HOME_BUTTON: 'iframe_home_button',
  EPCP_HOME_BUTTON_HISTORY_BACK_DISABLE: 'homeButtonHistoryBackDisable',
  EPCP_AVAILABLE_BETS: 'available_bets',
  EPCP_MAX_WIN_LIMIT: 'maxWinLimit',
  EPCP_SHOW_MAX_WIN_ON_START: 'showMaxWinOnStart',
  EPCP_CURRENCY: 'currency',
  EPCP_CURRENCIES_DATA: 'currencies',
  EPCP_DEFAULT_BET_INDEX: 'defaultBetIndex',
  EPCP_CANCEL_ENABLED: 'cancelEnabled',
  EPCP_SHOW_RULES_ON_START: 'showRulesOnStart',
  EPCP_HIDE_RULES_DOWNLOAD_BUTTON: 'hideRulesDownloadButton',
  EPCP_DISABLE_AUTO_PLAY: 'disableAutoplay',
  EPCP_SKIP_BLOCKED: 'skipBlocked',
  EPCP_ROUND_DURATION: 'roundDuration',
  EPCP_SHOW_OPERATOR_BETS_IDS: 'showOperatorBetsIds',
  EPCP_DISABLE_BUY_BONUS: 'disableBuyBonus',
  EPCP_DISABLE_SPIN_BY_SPACE: 'disableSpinBySpace',
  EPCP_SESSION: 'session',
  EPCP_GAME_CONFIG: 'game_config',
  EPCP_MESSAGE_EVENTS: 'messageEvents',
  EPCP_DISPLAY_RTP: 'displayRtp',
  EPCP_SHOW_NET_POSITION: 'showNetPosition',
  EPCP_SHOW_GAME_STATE: 'showGameState',
  EPCP_SHOW_STOP_WATCH: 'showStopwatch',
  EPCP_SHOW_CLOCK: 'showClock',
  EPCP_CERTIFICATION_NAME: 'certificationName',
  EPCP_ENABLE_REALITY_CHECK: 'enableRealityCheck',
  EPCP_LIGHT_MODE: 'lightMode',
  EPCP_SLOT_MODE: 'slotMode',
  EPCP_HIDE_NON_PLAYER_CURRENCIES: 'hideNonPlayerCurrencies',
  EPCP_IS_MAX_BETS_COUNT_ONE: 'isMaxBetsCountOne',
  EPCP_USE_LOW_QUALITY_ASSETS: 'useLowQualityAssets',
  EPCP_CRUSH_GAMES_BET_BUTTON_VALUES: 'crushGamesBetButtonValues',
};

export default ePartnerConfigProperties;
